import * as React from "react"
import styled from "styled-components";

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import Head from "@components/policy/Head";
import Article from "@components/policy/Article";

import privacyPolicyArr from "@enums/privacy-policy"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo
        title={'プライバシーポリシー - マッチングアプリ アナライザー'}
      />
      <Container>
        <Head
            title='プライバシーポリシー'
            date='2023年12月20日'
        />
        <Body>
          {privacyPolicyArr.map((elem, index)  => (
            <Article
              key={`article${index + 1}`}
              title={elem.title}
              text={elem.text}
            />
          ))}
        </Body>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
`;

const Body = styled.div`
  padding: 0px 35px 40px 35px;
`;


export default NotFoundPage
