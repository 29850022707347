import React from "react";
import styled from "styled-components";

const Article = props => {
  const {
    title,
    text
  } = props;
  return (
    <Container>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 30px;
`;

const Title = styled.h2`
  margin-bottom: 9px;
  color: #494949;
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
`;

const Text = styled.span`
  color: #494949;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  white-space: pre-wrap;
`;

export default Article;