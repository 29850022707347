import React from "react";
import styled from "styled-components";

const Head = props => {
  const {
    title,
    date
  } = props;
  return (
    <Container>
      <Title>{title}</Title>
      <Date>更新日 {date}</Date>
    </Container>
  );
};

const Container = styled.div`
  padding: 60px 0px 30px 30px;
  border-bottom: solid 1px #D9D9D9;
`;

const Title = styled.h1`
  color: #494949;
  font-family: Lato;
  font-size: 31.25px;
  font-weight: 700;
`;

const Date = styled.span`
  margin-top: 6px;
  color: #7C7C7C;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
`;

export default Head;