const privacyPolicyArr = [
  {
    title: '1．個人情報とは',
    text:
`本サービスにおいて「個人情報」とは、氏名、住所、電話番号、メールアドレス、銀行口座番号などユーザ個人を識別できる情報をいいます。また、他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものをも含みます。本サービスでは、ユーザが個人情報を送信することを了承した場合にのみ、それらの情報が収集されます。`
  },
  {
    title: '2．個人情報の収集目的',
    text:
`本サービスでは、ユーザにご利用いただくにあたって、個人情報を登録していただく場合があります。ご登録いただいたユーザの個人情報は、以下に掲げる目的のために限定して使用し、弊社はその情報を厳重に管理します。

・本サービスを提供・管理・運営するため
・ユーザが本サービスを利用するために、弊社と必要な連絡をするため
・本サービスを運営するために、個人情報が個人の特定されない統計データを収集し、アンケートを作成・送付するため
・安全かつ安心な取引を実現するために、エスクローサービスを提供するため
・安全かつ安心な取引を実現するために、本人確認サービスを提供するため
・信頼性を向上するために、電話確認サービスを提供するため
・信頼性を向上するために、ユーザーランクサービスを提供するため
・ユーザからのお問い合わせに返答するため
・その他、ユーザの同意を得た目的のため
上記以外に個人情報の利用の必要がある場含は、ユーザの皆様方に、事前にその旨を連絡させて頂きます。`
  },
  {
    title: '3．個人情報の利用',
    text:
`本サービスがユーザの個人情報を利用または第三者に開示するのは、下記の場合に限ります。

・ユーザが同意した場合
・ユーザと本サービスが当事者として契約を締結した場合で、その契約の準備または履行のために必要な場合
・本サービスが遵守すべき法的義務のために必要な場合
・ユーザの生命・健康・財産等の重大な利益を保護するために必要な場合
・公共の利益の保護または本サービスもしくは個人情報を開示する対象となる第三者の法令に基づく権限行使のために必要な場合
・本サービスが、ユーザの属性の集計、分析を行い、個人が識別・特定できないように加工したもの(以下、「統計資料」という)を作成し、新規サービスの開発等の業務遂行のために利用、処理する場合。また、本サービスが、統計資料を業務提携先等に提供する場合
・ユーザの利益を侵害しない範囲内において、本サービス及び個人情報の開示の対象となる第三者その他当事者の利益のため必要な場合
・個人情報の開示が裁判所、検察庁もしくは行政機関の命令に基づく場含、または、警察等の行政機関から捜査権限を定める法令に基づき正式な照会を受けた場合、または、弁護士法23条の2等の法令に基づき開示を求める場合`
  },
  {
    title: '4．クッキーの利用',
    text:
`弊社は、本サービスのコンテンツの提供を管理・維持したり、新しいサービスを検討したり、ログイン情報管理や広告配信のために端末情報をクッキーにて利用しております。ユーザがクッキーの受入を拒否する場合、本サービスの一部が利用できないことがあります。`
  },
  {
    title: '5．プライバシー・ポリシーの変更・改訂について',
    text:
`弊社は、ユーザに対して事前に通知することなく、プライバシーポリシーの全部または一部を変更し、改訂することがあります。重要な変更があるときには、本サイトでお知らせいたします。`
  },
  {
    title: '6．免責',
    text:
`弊社は、個人情報を保護するために善管注意義務をつくします。しかし、悪意の第三者による個人情報の盗用などについては、この責任に任じないものとします。`
  },
];

export default privacyPolicyArr;